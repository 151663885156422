import {Form as FForm} from "react-final-form";
import {Mutator, Tools, MutableState} from "final-form";

import {distanceToObj, distances, distancesType, MilesToKm, timeType} from "./Globals";
import {MilesField, MetersField, TimeField} from "./CustomFields";
import './Form.css';

// @TODO bugs:
// 1. Distance = 2 miles, time = 14 minutes, 1 mile = 6.60 instead of 7
// 2. 2mi 12.53 close and reload => 1.1759, 12.52


const Form = () => {
    const pace = parseInt(localStorage.getItem('pace') || '0');
    const distance = parseInt(localStorage.getItem('distance') || '0');
    return (
        <FForm onSubmit={onSubmit} mutators={{setValue, setDefault, setDefaultTime }} distances={distances} name="calcForm">
            {(props) => (
                <form>
                    <div className="distance custom">
                        <MilesField onChange={props.form.mutators.setValue} onBlur={props.form.mutators.setDefault}
                                    default={distanceToObj<'miles'>(distance, 'miles')} />
                        <MetersField onChange={props.form.mutators.setValue} onBlur={props.form.mutators.setDefault} default={distanceToObj<'meters'>(distance, 'meters')} />
                    </div>
                    <TimeField distance="no" onChange={props.form.mutators.setValue}
                               onBlur={props.form.mutators.setDefault}
                               default={defaultTime(pace, 'no')}/>
                    <div className="distance-list">
                        {distances.map((value, index, array) => (
                            <div className={ index % 2 ? 'odd' : 'even'} key={value}>
                                <div className="distance">{value}</div>
                                <TimeField distance={value} onChange={props.form.mutators.setValue}
                                           onBlur={props.form.mutators.setDefaultTime}
                                           default={defaultTime(pace, value)}
                                />
                            </div>
                        ))}
                    </div>
                </form>
            )}
        </FForm>
    );
}

const onSubmit = (form: object) => console.log(JSON.stringify(form))

const setDefault: Mutator = (args: Array<any>, state, tools) => {
    const active = args[0].target.name;
    tools.changeValue(state, active, (value: number): string => {
        if (value === undefined) return '0';
        return value.toString().match("[^0][0-9]*$") ? value.toString().match("[^0][0-9]*$")![0] : '0';
    });
}

const setDefaultTime: Mutator = (args: Array<any>, state, tools) => {
    const active = args[0].target.name;
    tools.changeValue(state, active, (value: number): string => {
        if (value === undefined) return '00';
        return value.toString().padStart(2, '0');
    });
}

const setValue: Mutator = (args: Array<any>, state, tools) => {
    const active:string = args[0].target.name;
    const value = args[0].target.value;
    let distance:number, time:number, empty_string: boolean = false;
    if (validate(value, active)) {
        if (value === '') empty_string = true;
        tools.changeValue(state, active, () => parseInt(value) || 0);
        switch (active) {
            case 'miles':
            case 'yards':
                const kilometersFloat = (parseInt(tools.getIn(state.formState.values, 'miles')) + parseInt(tools.getIn(state.formState.values, 'yards')) / 1760) * MilesToKm;
                distance = kilometersFloat * 1000;
                localStorage.setItem('distance', distance.toString());
                time = getTime('no', tools, state);
                setTimes(time, distance, 'no', tools, state);
                const kilometersRound = Math.floor(kilometersFloat);
                const metersRound = ((kilometersFloat - kilometersRound) * 1000).toFixed(0);
                tools.changeValue(state, 'kilometers', () => kilometersRound);
                tools.changeValue(state, 'meters', () => metersRound);
                break;
            case 'kilometers':
            case 'meters':
                distance = parseInt(tools.getIn(state.formState.values, 'kilometers')) * 1000 + parseInt(tools.getIn(state.formState.values, 'meters'));
                localStorage.setItem('distance', distance.toString());
                time = getTime('no', tools, state);
                setTimes(time, distance, 'no', tools, state);
                const {miles, yards} = distanceToObj<'miles'>(distance, 'miles');
                tools.changeValue(state, 'miles', () => miles.toString());
                tools.changeValue(state, 'yards', () => yards.toString());
                break;
            default:
                // Time changed.
                // Get active time.
                const distanceName: distancesType  = active.split('.')[1] as distancesType;
                time = getTime(distanceName, tools, state);
                // Get active distance.
                distance = parseDistance(distanceName, state, tools);
                setTimes(time, distance, distanceName, tools, state);
                break;
        }
        if (empty_string) tools.changeValue(state, active, () => '');
    }
}

/**
 *
 * @param time
 *   Time that was set on some distance
 * @param distance
 *   Distance in meters on which time was set
 * @param distanceName
 *   Name of this distance. Seems redundant.
 * @param tools
 * @param state
 */
const setTimes = (time:number, distance:number, distanceName: distancesType, tools: Tools<object, object>, state: MutableState<object, object>) => {
    if (distanceName === '1km') {
        // @TODO set pace just on window.unload
        window.localStorage.setItem("pace", time.toString());
    }
    ([...distances, 'no'] as distancesType[]).forEach((val: distancesType, ind, arr) => {
        if (val === distanceName) return;
        const distanceTime = distance ? time * parseDistance(val, state, tools) / distance : 0;
        if (val === '1km') {
            window.localStorage.setItem("pace", distanceTime.toString());
        }
        const distanceHours = Math.floor(distanceTime / 3600);
        let remainder = distanceTime % 3600;
        const distanceMinutes = Math.floor(remainder / 60);
        const distanceSeconds = (remainder % 60).toFixed(0);
        tools.changeValue(state, 'hours.' + val, () => distanceHours);
        tools.changeValue(state, 'minutes.' + val, () => distanceMinutes.toString().padStart(2, '0'));
        tools.changeValue(state, 'seconds.' + val, () => distanceSeconds.toString().padStart(2, '0'));
    })
}



const defaultTime = (pace: number, distanceName: distancesType): timeType => {
    const distanceTime = pace * parseDistance(distanceName) / 1000;
    const distanceHours = Math.floor(distanceTime / 3600);
    let remainder = distanceTime % 3600;
    const distanceMinutes = Math.floor(remainder / 60);
    const distanceSeconds = Math.round(remainder % 60);
    return {hours: distanceHours, minutes: distanceMinutes, seconds: distanceSeconds};
};

const getTime = (distanceName: distancesType, tools: Tools<object, object>, state: MutableState<object, object>):number =>
    parseInt(tools.getIn(state.formState.values, 'hours.' + distanceName)) * 3600 + parseInt(tools.getIn(state.formState.values, 'minutes.' + distanceName)) * 60 + parseInt(tools.getIn(state.formState.values, 'seconds.' + distanceName));

const parseDistance = (distanceName: distancesType, state?: MutableState<object, object>, tools?:Tools<object, object>):number => {
    if (distanceName === 'no') {
        if (tools === undefined || state === undefined) {
            const storedDistance = localStorage.getItem('distance') || "0";
            if (!storedDistance) {
                throw new Error('Form state or value should be supplied for custom distances');
            }
            else return parseInt(storedDistance);
        }
        return parseInt(tools.getIn(state.formState.values, 'kilometers')) * 1000 + parseInt(tools.getIn(state.formState.values, 'meters'));
    } else {
        if (distanceName === 'marathon') return 42195;
        else if (distanceName === 'half-marathon') return 21097;
        else if (distanceName.slice(-2) === 'km') return parseInt(distanceName.slice(0, -2)) * 1000;
        else if (distanceName.slice(-2) === 'mi')  return parseInt(distanceName.slice(0, -2)) * MilesToKm * 1000;
        else if (distanceName.slice(-1) === 'm')  return parseInt(distanceName.slice(0, -1));
    }
    return 0;
}

const validate = (value:string, name:string) => {
    const maxYards = 1739;
    const maxMeters = 999;
    const maxMinutes = 59;
    const maxSeconds = 59;
    let max:number = 0;
    if (!value.match(/^[0-9]*$/)) return false;
    if (value === "") return true;
    const index = name.split('.')[0]
    switch (index) {
        case 'yards': max = maxYards; break;
        case 'meters': max = maxMeters; break;
        case 'minutes': max = maxMinutes; break;
        case 'seconds': max = maxSeconds; break;
        default: max = 100;
    }
    return parseInt(value) <= max;

}

export default Form;