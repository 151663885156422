import './App.css';
import Form from "./Form";
import {Analytics} from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react"

function App() {
  return (
    <div className="App">
      <Form />
      <Analytics />
      <SpeedInsights />
    </div>
  );
}

export default App;
