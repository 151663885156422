const distances = ['100m', '200m', '300m', '400m', '600m', '800m', '1km', '1500m', '1mi', '2km', '3km', '2mi', '5km', '10km', 'half-marathon', 'marathon'] as const;
//@todo create types for time and distances and move global constants and types into separate file.
type distancesType = typeof distances[number] | 'no';
type unitsType = {miles: ["miles", "yards"], meters: ["kilometers", "meters"]}
type distanceType<T extends keyof unitsType> = {[Property in unitsType[T][number]]: number}


type timeType = {hours: number, minutes: number, seconds: number}


const MilesToKm = 1.609344;

const distanceToObj = <T extends keyof unitsType>(meters: number, units: T): distanceType<T> => {
    let result: distanceType<T>;
    if (units === 'miles') {
        const milesFloat = meters / 1000 / MilesToKm;
        const milesRound = Math.floor(milesFloat);
        const yardsRound = Math.round((milesFloat - milesRound) * 1760);
        result = {miles: milesRound, yards: yardsRound} as distanceType<T>
    }
    else {
        const kilometersFloat = meters / 1000;
        const kilometersRound = Math.floor(kilometersFloat);
        const metersRound = Math.round((kilometersFloat - kilometersRound) * 1000);
        result = {kilometers: kilometersRound, meters: metersRound} as distanceType<T>
    }
    return result
}

export {distances, distancesType, distanceType, timeType, MilesToKm, distanceToObj};
