import {useId} from "react";
import {Field} from "react-final-form";
import {Mutator} from "final-form";
import {distancesType, timeType, distanceType} from "./Globals";

interface FieldProp {
    onChange: Mutator,
    onBlur: Mutator,
}

interface MilesFieldProp extends FieldProp {
    default?: distanceType<'miles'>
}

interface MetersFieldProp extends FieldProp {
    default?: distanceType<'meters'>
}

interface TimeFieldProp extends FieldProp{
    distance: distancesType
    default?: timeType
}

const MilesField = (props:MilesFieldProp)  => {
    const defaultDistance = props.default || {miles: 0, yards: 0}
    const yardsId = useId(), milesId = useId()
    return (
        <div className='distance miles'>
            <Field name="miles" component="input" type="number" defaultValue={defaultDistance.miles.toString()}
                   onBlur={props.onBlur} onChange={props.onChange} id={milesId}/>
            <label htmlFor={milesId}>mi</label>
            <Field name="yards" component="input" type="number" defaultValue={defaultDistance.yards.toString()} min='0' max='1739'
                   onBlur={props.onBlur} onChange={props.onChange} id={yardsId}/>
            <label htmlFor={yardsId}>yd</label>
        </div>
    )
}

const MetersField = (props:MetersFieldProp) => {
    const defaultDistance = props.default || {kilometers: 0, meters: 0}
    const metersId = useId(), kilometersId = useId()
    return (
        <div className="distance meters">
            <Field name="kilometers" component="input" type="number" defaultValue={defaultDistance.kilometers.toString()} min='0' max='100'
                   onBlur={props.onBlur} onChange={props.onChange} id={kilometersId}/>
            <label htmlFor={kilometersId}>km</label>
            <Field name="meters" component="input" type="number" defaultValue={defaultDistance.meters.toString()} min='0' max='999'
                   onBlur={props.onBlur} onChange={props.onChange} id={metersId}/>
            <label htmlFor={metersId}>m</label>
        </div>
    )
}

const TimeField = (props:TimeFieldProp) => {
    const defaultTime = props.default || {hours: 0, minutes: 0, seconds: 0}
    const hoursId = useId(), minutesId = useId(), secondsId = useId()
    return (
        <div className="time">
            <Field name={`hours.${props.distance}`} component="input" type="number" defaultValue={defaultTime.hours.toString()} min='0' max='59'
                   onBlur={props.onBlur} onChange={props.onChange} id={hoursId}/>
            <label htmlFor={hoursId}>hr</label>
            <Field name={`minutes.${props.distance}`} component="input" type="number" defaultValue={defaultTime.minutes.toString().padStart(2,'0')} min='0' max='59'
                   onBlur={props.onBlur} onChange={props.onChange} id={minutesId}/>
            <label htmlFor={minutesId}>min</label>
            <Field name={`seconds.${props.distance}`} component="input" type="number" defaultValue={defaultTime.seconds.toString().padStart(2,'0')} min='0' max='59'
                   onBlur={props.onBlur} onChange={props.onChange} id={secondsId}/>
            <label htmlFor={secondsId}>sec</label>
        </div>
    );
}

export {MilesField, MetersField, TimeField};
